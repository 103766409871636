import { AccountStatus } from '@capital-markets-gateway/api-client-identity';
import { systemSubdomain } from '@cmg/auth';
import { apiTypes, duckPartFactory, reduxUtil } from '@cmg/common';
import { Action, combineReducers } from 'redux';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import * as accountApiClient from '../../../common/api/accountApiClient';
import systemManagementApiClient, {
  GetAccountPermissionsResponse,
  GetAccountResponse,
  UpdateAccountResponse,
  UpdateAccountStatusResponse,
  UpdateAccountTraitsResponse,
} from '../../../common/api/systemManagementApiClient';
import { RootState } from '../../../common/redux/rootReducer';
import { UUID } from '../../../types/common';
import {
  Account,
  AccountPermission,
  AccountUpdate,
  MyAccountUpdate,
} from '../../../types/domain/account/account';
import { AccountType } from '../../../types/domain/account/constants';
import { PasswordPolicy } from '../../../types/domain/password-policy/passwordPolicy';
import { LimitedTrait } from '../../../types/domain/trait/LimitedTrait';
import { selectSelfSubdomain } from '../../shared/ducks';

export const fetchAccountPermissionsDuckParts = duckPartFactory.makeAPIDuckParts<
  undefined,
  {
    permissions: AccountPermission[];
  }
>({
  prefix: 'global_management/FETCH_ACCOUNT_PERMISSIONS',
});

const assignTraitsDuckParts = duckPartFactory.makeAPIDuckParts<
  { accountId: string; traitCodes: string[] },
  LimitedTrait[]
>({
  prefix: 'GLOBAL_MANAGEMENT/UPDATE_ACCOUNT_TRAITS',
});

/**
 * ACTION TYPES
 */
export enum ActionTypes {
  RESET_ACCOUNT_DETAIL_DUCK = 'global_management/RESET_ACCOUNT_DETAIL_DUCK',
  FETCH_ACCOUNT_REQUEST = 'global_management/FETCH_ACCOUNT_REQUEST',
  FETCH_ACCOUNT_SUCCESS = 'global_management/FETCH_ACCOUNT_SUCCESS',
  FETCH_ACCOUNT_FAILURE = 'global_management/FETCH_ACCOUNT_FAILURE',
  UPDATE_ACCOUNT_REQUEST = 'global_management/UPDATE_ACCOUNT_REQUEST',
  UPDATE_ACCOUNT_SUCCESS = 'global_management/UPDATE_ACCOUNT_SUCCESS',
  UPDATE_ACCOUNT_FAILURE = 'global_management/UPDATE_ACCOUNT_FAILURE',
  ACCOUNT_UPDATED = 'global_management/ACCOUNT_UPDATED',
  UPDATE_ACCOUNT_STATUS_REQUEST = 'global_management/UPDATE_ACCOUNT_STATUS_REQUEST',
  UPDATE_ACCOUNT_STATUS_SUCCESS = 'global_management/UPDATE_ACCOUNT_STATUS_SUCCESS',
  UPDATE_ACCOUNT_STATUS_FAILURE = 'global_management/UPDATE_ACCOUNT_STATUS_FAILURE',
  UPDATE_ACCOUNT_STATUS_RESET = 'global_management/UPDATE_ACCOUNT_STATUS_RESET',
  ACCOUNT_PASSWORD_POLICY_UPDATED = 'management/ACCOUNT_PASSWORD_POLICY_UPDATED',
}

/**
 * ACTION CREATORS
 */
export const resetAccountDetailDuck = () => ({
  type: ActionTypes.RESET_ACCOUNT_DETAIL_DUCK,
});

export const fetchAccount = (accountSubdomain: string) => ({
  type: ActionTypes.FETCH_ACCOUNT_REQUEST,
  payload: {
    accountSubdomain,
  },
});

export const fetchAccountSuccess = (params: { account: Account }) => ({
  type: ActionTypes.FETCH_ACCOUNT_SUCCESS,
  payload: {
    account: params.account,
  },
});

export const fetchAccountFailure = ({ error }) => ({
  type: ActionTypes.FETCH_ACCOUNT_FAILURE,
  payload: {
    error,
  },
});

export const updateAccount = (accountUpdates: AccountUpdate | MyAccountUpdate) => ({
  type: ActionTypes.UPDATE_ACCOUNT_REQUEST,
  payload: accountUpdates,
});

export const updateAccountSuccess = () => ({
  type: ActionTypes.UPDATE_ACCOUNT_SUCCESS,
});

export const updateAccountFailure = ({ error }) => ({
  type: ActionTypes.UPDATE_ACCOUNT_FAILURE,
  payload: {
    error,
  },
});

export const updateAccountStatus = (params: {
  status: AccountStatus;
  accountId: UUID;
  cmgEntityKey?: string;
}) => ({
  type: ActionTypes.UPDATE_ACCOUNT_STATUS_REQUEST,
  payload: params,
});

export const updateAccountStatusSuccess = (params: { status: AccountStatus }) => ({
  type: ActionTypes.UPDATE_ACCOUNT_STATUS_SUCCESS,
  payload: params,
});

export const updateAccountStatusFailure = ({ error }) => ({
  type: ActionTypes.UPDATE_ACCOUNT_STATUS_FAILURE,
  payload: {
    error,
  },
});

export const resetUpdateAccountStatus = () => ({
  type: ActionTypes.UPDATE_ACCOUNT_STATUS_RESET,
});

/**
 * If the account is updated from another duck within the account-detail feature, it should
 * dispatch this action to update the account-detail level account.
 */
export const accountUpdated = (params: { account: Account }) => ({
  type: ActionTypes.ACCOUNT_UPDATED,
  payload: {
    account: params.account,
  },
});

export const accountPasswordPolicyUpdated = (params: { passwordPolicy: PasswordPolicy }) => ({
  type: ActionTypes.ACCOUNT_PASSWORD_POLICY_UPDATED,
  payload: {
    passwordPolicy: params.passwordPolicy,
  },
});

export const fetchAccountPermissions = fetchAccountPermissionsDuckParts.actionCreators.request;
type fetchAccountPermissionsAction = ReturnType<typeof fetchAccountPermissions>;

/**
 * Update the traits on an account - overwrites existing traits
 */
export const assignTraits = assignTraitsDuckParts.actionCreators.request;
type AssignTraitsAction = ReturnType<typeof assignTraits>;

// exported for testing only
export const assignTraitsSuccess = assignTraitsDuckParts.actionCreators.success;
type AssignTraitsSuccessAction = ReturnType<typeof assignTraitsSuccess>;

type Actions = {
  [ActionTypes.RESET_ACCOUNT_DETAIL_DUCK]: ReturnType<typeof resetAccountDetailDuck>;
  [ActionTypes.FETCH_ACCOUNT_REQUEST]: ReturnType<typeof fetchAccount>;
  [ActionTypes.FETCH_ACCOUNT_SUCCESS]: ReturnType<typeof fetchAccountSuccess>;
  [ActionTypes.FETCH_ACCOUNT_FAILURE]: ReturnType<typeof fetchAccountFailure>;
  [ActionTypes.UPDATE_ACCOUNT_REQUEST]: ReturnType<typeof updateAccount>;
  [ActionTypes.UPDATE_ACCOUNT_SUCCESS]: ReturnType<typeof updateAccountSuccess>;
  [ActionTypes.UPDATE_ACCOUNT_FAILURE]: ReturnType<typeof updateAccountFailure>;
  [ActionTypes.ACCOUNT_UPDATED]: ReturnType<typeof accountUpdated>;
  [ActionTypes.ACCOUNT_PASSWORD_POLICY_UPDATED]: ReturnType<typeof accountPasswordPolicyUpdated>;
  [ActionTypes.UPDATE_ACCOUNT_STATUS_REQUEST]: ReturnType<typeof updateAccountStatus>;
  [ActionTypes.UPDATE_ACCOUNT_STATUS_SUCCESS]: ReturnType<typeof updateAccountStatusSuccess>;
  [ActionTypes.UPDATE_ACCOUNT_STATUS_FAILURE]: ReturnType<typeof updateAccountStatusFailure>;
  [ActionTypes.UPDATE_ACCOUNT_STATUS_RESET]: ReturnType<typeof resetUpdateAccountStatus>;
};

/**
 * REDUCERS
 */
const { createReducer } = reduxUtil;

export type ReducerState = {
  // Shared representation of the account used for all account-detail/* routes.
  account: Account | null;
  error: apiTypes.GenericServerError | null;
  loading: boolean;
  updateError: apiTypes.GenericServerError | null;
  updateSubmitting: boolean;
  accountStatus: {
    error: apiTypes.GenericServerError | null;
    submitting: boolean;
  };
  accountPermissions: typeof fetchAccountPermissionsDuckParts.initialState;
  assignTraitsParts: typeof assignTraitsDuckParts.initialState;
};

export const initialState: ReducerState = {
  error: null,
  account: null,
  loading: false,
  updateError: null,
  updateSubmitting: false,
  accountStatus: {
    error: null,
    submitting: false,
  },
  accountPermissions: fetchAccountPermissionsDuckParts.initialState,
  assignTraitsParts: assignTraitsDuckParts.initialState,
};

const errorReducer = createReducer<ReducerState['error'], Actions>(initialState.error, {
  [ActionTypes.FETCH_ACCOUNT_REQUEST]: () => null,
  [ActionTypes.FETCH_ACCOUNT_SUCCESS]: () => null,
  [ActionTypes.FETCH_ACCOUNT_FAILURE]: (curState, { payload }) => payload.error,
});

const accountReducer = createReducer<ReducerState['account'], Actions>(initialState.account, {
  [ActionTypes.FETCH_ACCOUNT_REQUEST]: () => null,
  [ActionTypes.FETCH_ACCOUNT_SUCCESS]: (curState, { payload }) => payload.account,
  [ActionTypes.ACCOUNT_UPDATED]: (curState, { payload }) => payload.account,
  [ActionTypes.ACCOUNT_PASSWORD_POLICY_UPDATED]: (curState, { payload }) =>
    curState === null
      ? null
      : {
          ...curState,
          passwordPolicy: payload.passwordPolicy,
        },
  [ActionTypes.UPDATE_ACCOUNT_STATUS_SUCCESS]: (curState, { payload: { status } }) =>
    curState === null
      ? null
      : {
          ...curState,
          status,
        },
  [assignTraitsDuckParts.actionTypes.SUCCESS]: (curState, action: AssignTraitsSuccessAction) =>
    curState === null
      ? null
      : {
          ...curState,
          traits: action.payload,
        },
});

const loadingReducer = createReducer<ReducerState['loading'], Actions>(initialState.loading, {
  [ActionTypes.FETCH_ACCOUNT_REQUEST]: () => true,
  [ActionTypes.FETCH_ACCOUNT_SUCCESS]: () => false,
  [ActionTypes.FETCH_ACCOUNT_FAILURE]: () => false,
});

const updateErrorReducer = createReducer<ReducerState['updateError'], Actions>(
  initialState.updateError,
  {
    [ActionTypes.UPDATE_ACCOUNT_REQUEST]: () => null,
    [ActionTypes.ACCOUNT_UPDATED]: () => null,
    [ActionTypes.UPDATE_ACCOUNT_SUCCESS]: () => null,
    [ActionTypes.UPDATE_ACCOUNT_FAILURE]: (curState, { payload }) => payload.error,
  }
);

const updateSubmitting = createReducer<ReducerState['updateSubmitting'], Actions>(
  initialState.updateSubmitting,
  {
    [ActionTypes.UPDATE_ACCOUNT_REQUEST]: () => true,
    [ActionTypes.UPDATE_ACCOUNT_SUCCESS]: () => false,
    [ActionTypes.UPDATE_ACCOUNT_FAILURE]: () => false,
  }
);

const accountStatusReducer = combineReducers<ReducerState['accountStatus']>({
  error: createReducer<ReducerState['accountStatus']['error'], Actions>(
    initialState.accountStatus.error,
    {
      [ActionTypes.UPDATE_ACCOUNT_STATUS_REQUEST]: () => null,
      [ActionTypes.UPDATE_ACCOUNT_STATUS_SUCCESS]: () => null,
      [ActionTypes.UPDATE_ACCOUNT_STATUS_FAILURE]: (curState, { payload }) => payload.error,
      [ActionTypes.UPDATE_ACCOUNT_STATUS_RESET]: () => null,
    }
  ),
  submitting: createReducer<ReducerState['accountStatus']['submitting'], Actions>(
    initialState.accountStatus.submitting,
    {
      [ActionTypes.UPDATE_ACCOUNT_STATUS_REQUEST]: () => true,
      [ActionTypes.UPDATE_ACCOUNT_STATUS_SUCCESS]: () => false,
      [ActionTypes.UPDATE_ACCOUNT_STATUS_FAILURE]: () => false,
      [ActionTypes.UPDATE_ACCOUNT_STATUS_RESET]: () => false,
    }
  ),
});

const combinedReducer = combineReducers<ReducerState>({
  error: errorReducer,
  account: accountReducer,
  loading: loadingReducer,
  updateSubmitting: updateSubmitting,
  updateError: updateErrorReducer,
  accountStatus: accountStatusReducer,
  accountPermissions: fetchAccountPermissionsDuckParts.reducer,
  assignTraitsParts: assignTraitsDuckParts.reducer,
});

// Wrap combinedReducer so we can take action on the full duck state.
const defaultReducer = (state: ReducerState = initialState, action: Action) => {
  if (action.type === ActionTypes.RESET_ACCOUNT_DETAIL_DUCK) {
    return combinedReducer(initialState, action);
  }

  return combinedReducer(state, action);
};

/**
 * SELECTORS
 */
const selectState = (state: RootState): ReducerState => state.adminAccountDetail;
export const selectError = state => selectState(state).error;
export const selectAccount = state => selectState(state).account;
export const selectAccountType = (state): AccountType | 'SYSTEM' | null => {
  const account = selectAccount(state);
  if (!account) {
    return null;
  }

  if (account.subdomain === systemSubdomain) {
    return 'SYSTEM';
  }

  return account.accountType;
};

export const selectLoading = state => selectState(state).loading;
export const selectUpdateError = state => selectState(state).updateError;
export const selectUpdateSubmitting = state => selectState(state).updateSubmitting;
export const selectAccountStatusSubmitting = state => selectState(state).accountStatus.submitting;
export const selectAccountStatusError = state => selectState(state).accountStatus.error;
export const selectAccountId = state => {
  const account = selectAccount(state);
  return account ? account.id : null;
};
export const selectAccountSubdomain = state => {
  const account = selectAccount(state);
  return account ? account.subdomain : null;
};
const fetchAccountPermissionsSelectors = fetchAccountPermissionsDuckParts.makeSelectors(
  state => selectState(state).accountPermissions
);
export const selectAccountPermissions = createSelector(
  fetchAccountPermissionsSelectors.selectData,
  data => (data ? data.permissions : [])
);
export const selectAccountPermissionsLoading = fetchAccountPermissionsSelectors.selectLoading;
export const selectAccountPermissionsError = fetchAccountPermissionsSelectors.selectError;
const assignTraitsSelectors = assignTraitsDuckParts.makeSelectors(
  state => selectState(state).assignTraitsParts
);
export const selectAssignTraitsParts = assignTraitsSelectors.selectAll;

/**
 * SAGAS
 */
export function* fetchAccountSaga({
  payload,
}: Actions[ActionTypes.FETCH_ACCOUNT_REQUEST]): SagaIterator {
  const { accountSubdomain } = payload;
  const selfSubdomain = yield select(selectSelfSubdomain);

  let response: accountApiClient.GetMyAccountResponse | GetAccountResponse;
  if (accountSubdomain === selfSubdomain) {
    response = yield call(accountApiClient.getMyAccount);
  } else {
    response = yield call(systemManagementApiClient.admin.getAccount, accountSubdomain);
  }

  if (response.ok) {
    const account = response.data;
    yield put(fetchAccountSuccess({ account }));
  } else {
    yield put(fetchAccountFailure({ error: response.data.error }));
  }
}

export function* updateAccountStatusSaga({
  payload,
}: Actions[ActionTypes.UPDATE_ACCOUNT_STATUS_REQUEST]): SagaIterator {
  const { status, accountId, cmgEntityKey } = payload;
  const response: UpdateAccountStatusResponse = yield call(
    systemManagementApiClient.admin.updateAccountStatus,
    accountId,
    status,
    cmgEntityKey
  );
  if (response.ok) {
    yield put(updateAccountStatusSuccess({ status }));
  } else {
    yield put(updateAccountStatusFailure({ error: response.data.error }));
  }
}

export function* updateAccountSaga({
  payload,
}: Actions[ActionTypes.UPDATE_ACCOUNT_REQUEST]): SagaIterator {
  const accountSubdomain = yield select(selectAccountSubdomain);
  const selfSubdomain = yield select(selectSelfSubdomain);

  let response: accountApiClient.UpdateMyAccountResponse | UpdateAccountResponse;
  if (accountSubdomain === selfSubdomain) {
    response = yield call(accountApiClient.updateMyAccount, payload as MyAccountUpdate);
  } else {
    response = yield call(systemManagementApiClient.admin.updateAccount, payload as AccountUpdate);
  }

  if (response.ok) {
    yield put(accountUpdated({ account: response.data }));
    yield put(updateAccountSuccess());
  } else {
    yield put(updateAccountFailure({ error: response.data.error }));
  }
}

export function* fetchAccountPermissionsSaga(action: fetchAccountPermissionsAction): SagaIterator {
  const accountSubdomain = yield select(selectAccountSubdomain);
  const selfSubdomain = yield select(selectSelfSubdomain);

  let response: accountApiClient.GetMyAccountPermissionsResponse | GetAccountPermissionsResponse;
  if (accountSubdomain === selfSubdomain) {
    response = yield call(accountApiClient.getMyAccountPermissions);
  } else {
    response = yield call(systemManagementApiClient.admin.getAccountPermissions, accountSubdomain);
  }

  if (response.ok) {
    const permissions: AccountPermission[] = response.data;
    yield put(fetchAccountPermissionsDuckParts.actionCreators.success({ permissions }));
  } else {
    yield put(fetchAccountPermissionsDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* assignTraitsSaga({ payload }: AssignTraitsAction): SagaIterator {
  const response: UpdateAccountTraitsResponse = yield call(
    systemManagementApiClient.admin.updateAccountTraits,
    payload.accountId,
    payload.traitCodes
  );
  if (response.ok) {
    yield put(assignTraitsDuckParts.actionCreators.success(response.data));
  } else {
    yield put(assignTraitsDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* adminAccountDetailSaga() {
  yield takeLatest(ActionTypes.FETCH_ACCOUNT_REQUEST, fetchAccountSaga);
  yield takeLatest(ActionTypes.UPDATE_ACCOUNT_REQUEST, updateAccountSaga);
  yield takeLatest(ActionTypes.UPDATE_ACCOUNT_STATUS_REQUEST, updateAccountStatusSaga);
  yield takeLatest<fetchAccountPermissionsAction>(
    fetchAccountPermissionsDuckParts.actionTypes.REQUEST,
    fetchAccountPermissionsSaga
  );
  yield takeLatest<AssignTraitsAction>(assignTraitsDuckParts.actionTypes.REQUEST, assignTraitsSaga);
}

export default defaultReducer;

import { ApolloClient, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { cacheSizes } from '@apollo/client/utilities';
import { accessTokenExpirationGraphqlInterceptor, getAccessToken } from '@cmg/auth';
import { loggerUtil } from '@cmg/common';

// Simple wrapper around window.fetch for use in apollo http link.
const fetcher = (...args) => {
  // @ts-ignore
  return window.fetch(...args);
};

// The URL to the .NET Gateway GraphQL API
const httpLink = createHttpLink({
  uri: '/gw/graphql',
  // Sets httpLink fetch to window.fetch, which datadog sets up its trace proxy on.
  // apollo client httplink must be doing something that is incompatible with how datadog proxies fetch.
  // (more info from a similar tool to datadog: https://docs.logrocket.com/docs/graphql)
  fetch: fetcher,
  // If we aren't specific on the accepts header, HotChocolate Fusion defaults to application/graphql-response+json
  // And according to the GraphQL spec this causes Fusion to return HttpStatus code 500 if response is { data: null }
  // see: https://github.com/ChilliCream/graphql-platform/blob/d11febb93623212584a5298f39540f155087dd75/src/HotChocolate/AspNetCore/src/AspNetCore/Serialization/DefaultHttpResponseFormatter.cs#L218
  headers: { accept: 'application/json' },
});

// We have pages that can load thousands of rows, with nested objects that also get cached.
// Setting a high cache size prevents us from reaching cache limits on a single page load.
// Otherwise we hit the cache limit on a single request like 'My Orders' and CPU spikes to 100% when making other requests on that page.
cacheSizes['inMemoryCache.executeSelectionSet'] = 500_000; // default 50_000
cacheSizes['inMemoryCache.executeSubSelectedArray'] = 500_000; // default 10_000

const cache = new InMemoryCache();

// We'll intercept requests to the GraphQL API, and
// append the authorization token.
const authLink = setContext((request, previousContext) => {
  const token = getAccessToken();
  return {
    headers: {
      ...previousContext.headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

/**
 * onError link is an error interceptor, it gets invoked automatically whenever there is an error
 */
const errorLink = onError(({ graphQLErrors, networkError }) => {
  const errorLog: string[] = [];
  const graphqlErrorCodes: string[] =
    graphQLErrors?.map(({ extensions }) => extensions?.code) ?? [];

  accessTokenExpirationGraphqlInterceptor(graphqlErrorCodes);

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      errorLog.push(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  }

  if (networkError) {
    errorLog.push(`[Network error]: ${networkError}`);
    loggerUtil.error(networkError, errorLog);
  }
});

const graphqlApiClient = new ApolloClient({
  link: from([authLink, errorLink, httpLink]),
  cache,
});

export default graphqlApiClient;

import {
  AccountStatus,
  DraftAccountRead as DraftAccount,
} from '@capital-markets-gateway/api-client-identity';
import { accessTokenExpirationRequestInterceptor } from '@cmg/auth';
import { apiTypes, apiUtil, UnpackPromise, urlUtil } from '@cmg/common';
import axios, { AxiosResponse } from 'axios';

import { CreateUserSignupDetailsDto } from '../../features/investor-signup/ducks';
import { UUID } from '../../types/common';
import {
  Account,
  AccountCreate,
  AccountPermission,
  AccountUpdate,
} from '../../types/domain/account/account';
import { AccountType, LicenseTypes } from '../../types/domain/account/constants';
import { ApiKey, ApiKeyCreate } from '../../types/domain/api-keys/api-key';
import {
  AzureAdConfigurationUpdate,
  AzureAdOpenIdMetadata,
} from '../../types/domain/identity-provider/configurations/azureAd';
import {
  OpenIdConnectConfigurationUpdate,
  OpenIdConnectMetadata,
} from '../../types/domain/identity-provider/configurations/openIdConnect';
import {
  Saml2PConfigurationUpdate,
  Saml2PMetadata,
} from '../../types/domain/identity-provider/configurations/saml2p';
import { IdentityProviderType } from '../../types/domain/identity-provider/constants';
import {
  IdentityProvider,
  IdentityProviderAzure,
  IdentityProviderOpenIdConnect,
  IdentityProviderSaml,
} from '../../types/domain/identity-provider/identityProvider';
import {
  PasswordPolicy,
  PasswordPolicyUpdate,
} from '../../types/domain/password-policy/passwordPolicy';
import { Role } from '../../types/domain/role/role';
import { LimitedTrait } from '../../types/domain/trait/LimitedTrait';
import { Trait } from '../../types/domain/trait/Trait';
import { UserStatus } from '../../types/domain/user/constants';
import { User, UserCreate, UserUpdate } from '../../types/domain/user/user';
import UserBasic from '../../types/domain/user/UserBasic';
import { accessTokenRequestInterceptor, responseErrorInterceptor } from './interceptors';
import { AccountUserFilters } from './types/AccountUserFilters';
import { getExcelDownload } from './util/axiosDownloadUtil';

/**
 * The management client
 **/
const client = axios.create({
  baseURL: '/api/v1',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
});

client.interceptors.request.use(accessTokenExpirationRequestInterceptor);
client.interceptors.request.use(accessTokenRequestInterceptor);
client.interceptors.response.use(undefined, responseErrorInterceptor);

/**
 * API Calls
 */

/**
 * Retrieve a list of accounts in the system
 */
export type AccountFilters = {
  searchText?: string;
  status?: AccountStatus;
  accountType?: AccountType;
  licenseType?: LicenseTypes;
  traits?: string[];
};
export type GetAccountParams = apiTypes.ListParams & AccountFilters;
const getAccounts = (params: GetAccountParams) => {
  const querystring = urlUtil.queryStringify(
    {
      includeTotals: true,
      orderField: 'name',
      orderDirection: apiTypes.SortDirection.ASC,
      ...params,
    },
    {
      arrayFormat: 'none',
    }
  );
  return client
    .get<{
      data: Account[];
      pagination: apiTypes.Pagination;
    }>(`/admin/accounts${querystring}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetAccountsResponse = UnpackPromise<ReturnType<typeof getAccounts>>;

/**
 * Retrieve a single account
 */
const getAccount = (accountSubdomain: string) => {
  return client
    .get<Account>(`/admin/accounts/${accountSubdomain}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetAccountResponse = UnpackPromise<ReturnType<typeof getAccount>>;

/**
 * Retrieve a list of draft accounts in the system
 */
export type DraftAccountFilters = {
  searchText?: string | null;
  investorName?: string | null;
  accountName?: string | null;
  accountSubdomain?: string | null;
  cmgEntityKey?: string | null;
  invitedBy?: string | null;
};
export type GetDraftAccountParams = apiTypes.ListParams & DraftAccountFilters;
const getDraftAccounts = (params: GetDraftAccountParams) => {
  const querystring = urlUtil.queryStringify(
    {
      includeTotals: true,
      orderField: 'status',
      orderDirection: apiTypes.SortDirection.DESC,
      ...params,
    },
    {
      arrayFormat: 'none',
    }
  );
  return client
    .get<{
      data: DraftAccount[];
      pagination: apiTypes.Pagination;
    }>(`/admin/draftAccounts${querystring}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetDraftAccountsResponse = UnpackPromise<ReturnType<typeof getDraftAccounts>>;

const updateInvestorSubdomain = (draftAccountId: string, investorSubdomain: string | null) => {
  return client
    .put<{ investorSubdomain: string | null }, AxiosResponse<DraftAccount>>(
      `/admin/draftAccounts/${draftAccountId}`,
      {
        investorSubdomain: investorSubdomain,
      }
    )
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type UpdateInvestorSubdomainResponse = UnpackPromise<
  ReturnType<typeof updateInvestorSubdomain>
>;

/**
 * Retrieve a list of accounts in the system
 */
export type GetAccountUsersParams = apiTypes.ListParams & AccountUserFilters;
const getAccountUsers = (accountSubdomain: string, params: GetAccountUsersParams) => {
  const querystring = urlUtil.queryStringify({
    includeTotals: true,
    orderField: 'lastName',
    orderDirection: apiTypes.SortDirection.ASC,
    ...params,
  });
  return client
    .get<{
      data: UserBasic[];
      pagination: apiTypes.Pagination;
    }>(`/admin/${accountSubdomain}/users${querystring}`) // TODO - why isn't `accounts` included in url?
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetAccountUsersResponse = UnpackPromise<ReturnType<typeof getAccountUsers>>;

/**
 * Download an xlsx export of all account users
 */
export const downloadAccountUsers = (accountSubdomain: string) => {
  return getExcelDownload({
    client,
    url: `/admin/${accountSubdomain}/users/export`,
  });
};
export type DownloadAccountUsersResponse = UnpackPromise<ReturnType<typeof downloadAccountUsers>>;

/**
 * Retrieve a single account user
 */
const getAccountUser = (accountSubdomain: string, userId: string) => {
  return client
    .get<User>(`/admin/${accountSubdomain}/users/${userId}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetAccountUserResponse = UnpackPromise<ReturnType<typeof getAccountUser>>;

/**
 * Update user account
 */
const updateAccountUser = (accountSubdomain: string, userId: string, user: UserUpdate = {}) => {
  return client
    .put<typeof user, AxiosResponse<User>>(`/admin/${accountSubdomain}/users/${userId}`, user)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type UpdateAccountUserResponse = UnpackPromise<ReturnType<typeof updateAccountUser>>;

/**
 * Update user account
 */
const updateAccountUserStatus = (accountSubdomain: string, userId: string, status: UserStatus) => {
  return client
    .put<{ status: UserStatus }, AxiosResponse<void>>(
      `/admin/${accountSubdomain}/users/${userId}/status`,
      { status }
    )
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type UpdateAccountUserStatusResponse = UnpackPromise<
  ReturnType<typeof updateAccountUserStatus>
>;

/**
 * Creates an account
 */
const createAccount = (account: AccountCreate) => {
  return client
    .post<AccountCreate, AxiosResponse<Account>>('/admin/accounts', account)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type CreateAccountResponse = UnpackPromise<ReturnType<typeof createAccount>>;

/**
 * Partially update an account
 */
const updateAccount = (account: AccountUpdate) => {
  return client
    .patch<AccountUpdate, AxiosResponse<Account>>(`/admin/accounts/${account.id}`, account)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type UpdateAccountResponse = UnpackPromise<ReturnType<typeof updateAccount>>;

/**
 * Creates an account user
 */
const createAccountUser = (user: UserCreate, sendInvite: boolean, accountSubdomain: string) => {
  return client
    .post<typeof user, AxiosResponse<User>>(
      `/admin/${accountSubdomain}/users?sendInvite=${sendInvite}`,
      user
    )
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type CreateAccountUserResponse = UnpackPromise<ReturnType<typeof createAccountUser>>;

/**
 * Resend invite email to account user
 */
const resendInviteEmail = (accountSubdomain: string, userId: string) => {
  return client
    .post<void>(`/admin/${accountSubdomain}/users/${userId}/invites`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type ResendInviteEmailResponse = UnpackPromise<ReturnType<typeof resendInviteEmail>>;

/**
 * unlock user account
 */
const unlockUser = (accountSubdomain: string, userId: string) => {
  return client
    .post<void>(`/admin/${accountSubdomain}/users/${userId}/unlock`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type UnlockUserResponse = UnpackPromise<ReturnType<typeof unlockUser>>;

/**
 * Admin - Reset user password email
 */
const resetUserPasswordRequest = (accountSubdomain: string, userId: string) => {
  return client
    .post<void>(`/admin/${accountSubdomain}/users/${userId}/resetPasswordRequests`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type ResetUserPasswordResponse = UnpackPromise<ReturnType<typeof resetUserPasswordRequest>>;

/**
 * Admin - Change username
 */
const changeUsernameRequest = (accountSubdomain: string, userId: string, email: string) => {
  return client
    .put<{ email: string }, AxiosResponse<void>>(
      `/admin/${accountSubdomain}/users/${userId}/email`,
      { email }
    )
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type ChangeUsernameResponse = UnpackPromise<ReturnType<typeof changeUsernameRequest>>;

/**
 * Retrieve a list of account roles
 */
export type AccountRolesFilters = {
  searchText?: string;
};
export type GetAccountRolesParams = apiTypes.ListParams & AccountFilters;
const getAccountRoles = (accountSubdomain: string, params: GetAccountRolesParams) => {
  const querystring = urlUtil.queryStringify({
    includeTotals: true,
    orderField: 'name',
    perPage: 100,
    orderDirection: apiTypes.SortDirection.ASC,
    ...params,
  });
  return client
    .get<{
      data: Role[];
      pagination: apiTypes.Pagination;
    }>(`/admin/${accountSubdomain}/roles${querystring}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetAccountsRolesResponse = UnpackPromise<ReturnType<typeof getAccountRoles>>;

/**
 * Retrieve an account role
 */
const getAccountRole = (accountSubdomain: string, roleId: string) => {
  return client
    .get<Role>(`/admin/${accountSubdomain}/roles/${roleId}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetAccountRoleResponse = UnpackPromise<ReturnType<typeof getAccountRole>>;

/**
 * Retrieve a list of users with the provided role
 */
export type GetAccountRoleUsersParams = apiTypes.ListParams;
const getAccountRoleUsers = (
  accountSubdomain: string,
  roleId: string,
  params: GetAccountRoleUsersParams
) => {
  const querystring = urlUtil.queryStringify({
    includeTotals: true,
    orderField: 'lastName',
    orderDirection: apiTypes.SortDirection.ASC,
    ...params,
  });
  return client
    .get<{
      data: UserBasic[];
      pagination: apiTypes.Pagination;
    }>(`/admin/${accountSubdomain}/roles/${roleId}/users${querystring}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetAccountRoleUsersResponse = UnpackPromise<ReturnType<typeof getAccountRoleUsers>>;

/**
 * Update Account User Roles
 */
const updateAccountUserRoles = (accountSubdomain: string, userId: string, roles: string[]) => {
  return client
    .put(`/admin/${accountSubdomain}/users/${userId}/roles`, roles)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type UpdateAccountUserRolesResponse = UnpackPromise<
  ReturnType<typeof updateAccountUserRoles>
>;

/**
 * Update Account Password Policy
 */
const updatePasswordPolicy = (accountId: string, payload: PasswordPolicyUpdate) => {
  return client
    .put<typeof payload, AxiosResponse<PasswordPolicy>>(
      `/admin/accounts/${accountId}/passwordPolicy`,
      payload
    )
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type UpdatePasswordPolicyResponse = UnpackPromise<ReturnType<typeof updatePasswordPolicy>>;

/**
 * Update Account status
 */
const updateAccountStatus = (accountId: UUID, status: AccountStatus, cmgEntityKey?: string) => {
  return client
    .put(`/admin/accounts/${accountId}/status`, { status, cmgEntityKey })
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type UpdateAccountStatusResponse = UnpackPromise<ReturnType<typeof updateAccountStatus>>;

/**
 * Assign Traits to an Account - overwrites existing traits
 */
const updateAccountTraits = (accountId: UUID, traitCodes: string[]) => {
  return client
    .put<{ codes: string[] }, AxiosResponse<LimitedTrait[]>>(
      `/admin/accounts/${accountId}/traits`,
      { codes: traitCodes }
    )
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type UpdateAccountTraitsResponse = UnpackPromise<ReturnType<typeof updateAccountTraits>>;

/**
 * Get a specific identity provider
 */
const getIdentityProvider = (accountId: UUID, providerType: IdentityProviderType) => {
  return client
    .get<IdentityProvider>(`/admin/accounts/${accountId}/identityProviders/${providerType}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetIdentityProviderResponse = UnpackPromise<ReturnType<typeof getIdentityProvider>>;

/**
 * Update the AzureAdOpenId identity provider configurations
 */
const updateAzureAdConfiguration = (accountId: UUID, configuration: AzureAdConfigurationUpdate) => {
  return client
    .put<typeof configuration, AxiosResponse<IdentityProviderAzure>>(
      `/admin/accounts/${accountId}/identityProviders/azureAdOpenId`,
      configuration
    )
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type UpdateAzureAdConfigurationResponse = UnpackPromise<
  ReturnType<typeof updateAzureAdConfiguration>
>;

/**
 * Update the Saml2p identity provider configurations
 */
const updateSamlConfiguration = (accountId: UUID, configurations: Saml2PConfigurationUpdate) => {
  return client
    .put<typeof configurations, AxiosResponse<IdentityProviderSaml>>(
      `/admin/accounts/${accountId}/identityProviders/saml2p`,
      configurations
    )
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type UpdateSamlConfigurationResponse = UnpackPromise<
  ReturnType<typeof updateSamlConfiguration>
>;

/**
 * Update the OpenID Connect identity provider configurations
 */
const updateOpenIdConnectConfiguration = (
  accountId: UUID,
  configurations: OpenIdConnectConfigurationUpdate
) => {
  return client
    .put<typeof configurations, AxiosResponse<IdentityProviderOpenIdConnect>>(
      `/admin/accounts/${accountId}/identityProviders/openIdConnect`,
      configurations
    )
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type UpdateOpenIdConnectConfigurationResponse = UnpackPromise<
  ReturnType<typeof updateOpenIdConnectConfiguration>
>;

/*
 * get AzureAdOpenId meta data
 * metadata is used by the system administrator to configure their azure ad open id identity provider.
 * metadata will always display when adding/updating the identity provider configuration
 */
const getAzureAdOpenIdMetadata = (accountId: UUID) => {
  return client
    .get<AzureAdOpenIdMetadata>(
      `/admin/accounts/${accountId}/identityProviders/azureAdOpenId/metadata`
    )
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetAzureAdOpenIdMetadataResponse = UnpackPromise<
  ReturnType<typeof getAzureAdOpenIdMetadata>
>;

/**
 * get Saml2p meta data.
 * metadata is used by the system administrator to configure their saml2p identity provider.
 * metadata will always display when adding/updating the identity provider configuration.
 */
const getSaml2pMetadata = (accountId: UUID) => {
  return client
    .get<Saml2PMetadata>(`/admin/accounts/${accountId}/identityProviders/saml2p/metadata`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetSaml2pMetadataResponse = UnpackPromise<ReturnType<typeof getSaml2pMetadata>>;

/*
 * get openIdConnect meta data
 * metadata is used by the system administrator to configure their open id connect identity provider.
 * metadata will always display when adding/updating the identity provider configuration
 */
const getOpenIdConnectMetadata = (accountId: UUID) => {
  return client
    .get<OpenIdConnectMetadata>(
      `/admin/accounts/${accountId}/identityProviders/openIdConnect/metadata`
    )
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetOpenIdConnectMetadataResponse = UnpackPromise<
  ReturnType<typeof getOpenIdConnectMetadata>
>;

/**
 * Retrieve a list of API Keys for an account in the system
 */
export type GetApiKeysParams = apiTypes.ListParams;
const getApiKeys = (accountSubdomain: string, listParams: apiTypes.ListParams) => {
  const querystring = urlUtil.queryStringify({
    includeTotals: true,
    ...listParams,
  });
  return client
    .get<{
      data: ApiKey[];
      pagination: apiTypes.Pagination;
    }>(`/admin/${accountSubdomain}/apiKeys${querystring}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetApiKeysResponse = UnpackPromise<ReturnType<typeof getApiKeys>>;

/**
 * Retrieve a single API key
 */
const getApiKey = (accountSubdomain: string, apiKeyId: string) => {
  return client
    .get<ApiKey>(`/admin/${accountSubdomain}/apiKeys/${apiKeyId}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetApiKeyResponse = UnpackPromise<ReturnType<typeof getApiKey>>;

/**
 * Creates an API Key
 */
const createApiKey = (apiKey: ApiKeyCreate, accountSubdomain: string) => {
  return client
    .post<typeof apiKey, AxiosResponse<ApiKey>>(`/admin/${accountSubdomain}/apiKeys`, apiKey)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type CreateApiKeyResponse = UnpackPromise<ReturnType<typeof createApiKey>>;

/**
 * Retrieve a list of available permissions for a certain account
 */
const getAccountPermissions = (accountSubdomain: string) => {
  return client
    .get<AccountPermission[]>(`/admin/${accountSubdomain}/permissions`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetAccountPermissionsResponse = UnpackPromise<ReturnType<typeof getAccountPermissions>>;

/**
 * Regenerates an API Key
 */
const regenerateApiKey = (accountSubdomain: string, apiKeyId: string) => {
  return client
    .put<ApiKey>(`/admin/${accountSubdomain}/apiKeys/${apiKeyId}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type PutRegenerateApiKeyResponse = UnpackPromise<ReturnType<typeof regenerateApiKey>>;

/**
 * Revokes an API Key
 */
const revokeApiKey = (accountSubdomain: string, apiKeyId: string) => {
  return client
    .delete<undefined | apiTypes.GenericServerError>(
      `/admin/${accountSubdomain}/apiKeys/${apiKeyId}`
    )
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type DeleteApiKeyResponse = UnpackPromise<ReturnType<typeof revokeApiKey>>;

/**
 * Get Platform-wide Product Traits
 */
const getTraits = () => {
  return client
    .get<Trait[]>(`/admin/traits`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetTraitsResponse = UnpackPromise<ReturnType<typeof getTraits>>;

/**
 * Get A Single Product Trait
 */
const getTrait = (traitCode: string) => {
  return client
    .get<Trait>(`/admin/traits/${traitCode}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetTraitResponse = UnpackPromise<ReturnType<typeof getTrait>>;

/**
 * Validate investor signup email address and retrive
 * investor signup info if email address is valid
 */
const validateInvestorSignupEmail = (token: string, emailAddress: string) => {
  return client
    .get<{ isMatchFound: boolean }>(`/investorSignup/email/${emailAddress}?token=${token}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type InvestorSignupEmailMatchResponse = UnpackPromise<
  ReturnType<typeof validateInvestorSignupEmail>
>;

/**
 * Validate investor signup token and show
 * investor signup form if token is valid
 */
const validateInvestorSignupToken = (token: string) => {
  return client
    .get<{ isValid: boolean }>(`/investorSignup/token/${token}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type InvestorSignupTokenValidResponse = UnpackPromise<
  ReturnType<typeof validateInvestorSignupToken>
>;

/**
 * Submit investor signup form. On success, the API should return
 * a 202 status code and null response
 */
const submitInvestorSignupForm = (values: CreateUserSignupDetailsDto) => {
  return client
    .post<typeof values, AxiosResponse<null>>(`/investorSignup/userDetail`, values)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type InvestorSignupValidResponse = UnpackPromise<
  ReturnType<typeof submitInvestorSignupForm>
>;

/**
 * Remap to other CMG entity key
 */
type RemapAccountConfig = {
  accountId: string;
  reason: string;
  cmgEntityKey: string;
};
const remapAccounts = (configs: RemapAccountConfig[]) => {
  return client
    .put<typeof configs, AxiosResponse<null>>(`/admin/accounts/remap`, configs)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};

const admin = {
  createAccount,
  getAccounts,
  getAccount,
  getTraits,
  getTrait,
  getAccountUsers,
  downloadAccountUsers,
  getAccountUser,
  getDraftAccounts,
  updateInvestorSubdomain,
  updateAccount,
  updateAccountUser,
  updateAccountUserStatus,
  updateAccountTraits,
  createAccountUser,
  getAccountRoles,
  updateAccountUserRoles,
  getAccountRole,
  getAccountRoleUsers,
  updatePasswordPolicy,
  updateAccountStatus,
  resendInviteEmail,
  resetUserPasswordRequest,
  changeUsernameRequest,
  unlockUser,
  getIdentityProvider,
  updateAzureAdConfiguration,
  updateSamlConfiguration,
  updateOpenIdConnectConfiguration,
  getAzureAdOpenIdMetadata,
  getSaml2pMetadata,
  getOpenIdConnectMetadata,
  getApiKeys,
  getApiKey,
  createApiKey,
  getAccountPermissions,
  regenerateApiKey,
  revokeApiKey,
  remapAccounts,
};

const investorSignup = {
  validateInvestorSignupEmail,
  validateInvestorSignupToken,
  submitInvestorSignupForm,
};

const systemManagementApiClient = {
  admin,
  investorSignup,
};

export default systemManagementApiClient;
